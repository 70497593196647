import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BroadcastService {

  coordinate$ : Subject<any> = new Subject<any>();

  constructor() { }
}
