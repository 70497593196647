import { ApiService } from 'src/app/services/api/api.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PublicApiService {

  constructor(private http: HttpClient, private apiService: ApiService) { }

  getBand(id: string): Observable<any> {
    return this.apiService.get('band/'+id)
  }
  getProfile(id: string): Observable<any> {
    return this.apiService.get('profile/'+id)
  }
  getVenue(venueId: string): Observable<any> {
    return this.apiService.get('venue/'+venueId);
  }
  getFoodTruck(ftId: string): Observable<any> {
    return this.apiService.get('food-truck/'+ftId);
  }
  get(url: string): Observable<any> {
    return this.http.get(environment.base_url + url);
  }
}
