import { NgModule } from '@angular/core';
// import { PicInitialsPipe } from './pic-initials/pic-initials.pipe';
import { SafePipe } from './dom-sanitizer/dom-sanitizer.pipe';

@NgModule({
  imports: [],
  declarations: [
    SafePipe
  ],
  exports: [
    SafePipe
  ]
})
export class CustomPipesModule {}
