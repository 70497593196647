import { Observable } from 'rxjs';
import { ApiService } from './../api/api.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class PushNotificationsService {

  constructor(private apiService: ApiService) {}

  user_device_token: string = null;

  updateDeviceToken(data): Observable<any> {
    return this.apiService.post('users/updateDeviceToken', data);
  }

}
