export interface pageView {
    id: string,
    title: string,
    url: string,
    icon: string,
    selected: boolean,
    collapsed?: boolean,
    class: string,
    pages?: pageView[]
};

export const pages:pageView[] = [
    {
        id: 'home',
        title: 'Home',
        url: '/',
        class: 'home',
        icon: ``,
        selected: false
    },
    {
        id: 'listing',
        title: 'Listing',
        url: '/band',
        class: 'listing',
        icon: ``,
        selected: false
    },
    {
        id: 'video-archive',
        title: 'Video Archive',
        url: '/',
        class: 'video-archive',
        icon: ``,
        selected: false
    },
    {
        id: 'signin',
        title: 'Sign In',
        url: '/login',
        class: 'signin',
        icon: ``,
        selected: false
    },
    {
        id: 'calendar',
        title: 'Calendar',
        url: '/calendar',
        class: 'calendar',
        icon: ``,
        selected: false
    },
    // {
    //     id: 'profile',
    //     title: 'My Profile',
    //     url: '/profile/0',
    //     icon: `
    //             <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
    //                 <path stroke-linecap="round" stroke-linejoin="round" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
    //             </svg>
    //         `,
    //     selected: false
    // }
]
