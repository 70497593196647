import { take } from 'rxjs/operators';
import { PushNotificationsService } from 'src/app/services/push-notifications/push-notifications.service';
import { AccountInformationService } from 'src/app/services/account-information/account-information.service';
import { Subject, Subscription } from 'rxjs';
/* eslint-disable @typescript-eslint/member-ordering */
import { Component, HostListener, OnInit, Optional, OnDestroy } from '@angular/core';
import { Router, RouterEvent } from '@angular/router';
import { MenuController, Platform, PopoverController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { AuthState } from './services/auth/auth.model';
import { AuthService } from './services/auth/auth.service';
import { pageView } from './services/menu/menu.pages';
import { MenuService } from './services/menu/menu.service';
import { PlatformService } from './services/platform/platform.service';
import { theme } from './services/theme/theme.model';
import { ThemeService } from './services/theme/theme.service';
import { PopoverComponent } from './components/popover/popover.component';
import { IsUserLoggedInService } from './services/user/is-user-logged-in.service';
import { environment } from 'src/environments/environment';
import { PublicApiService } from './services/api/public-api.service';
import { BroadcastService } from './services/share/broadcast.service';
import { FcmService } from './services/fcm.service';
import { ApiService } from './services/api/api.service';
import * as moment from 'moment';
import { Auth } from '@angular/fire/auth';
import { SubSink } from 'subsink';
import { DatePipe } from '@angular/common';
import { AdsService } from './services/ads/ads.service';
import { Browser } from '@capacitor/browser';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  isMobileDevice: boolean;
  isMobileApp: boolean;
  isiOSApp: boolean;
  pages$: Observable<pageView[]>;
  auth$: Observable<AuthState>;

  themes$: Observable<theme[]>;
  currentTheme$: Observable<string>;
  title = '';
  dropdown = false;
  currentTab = 'home';
  selectedPath = '/';
  public isUserLoggedIn = false;
  userLogged: Subject<boolean>;
  public finalStatus: boolean = false;
  public displayName: string = '';
  public accountType: string = '';
  public profilePic: string = '../assets/images/profile-placeholder.png';
  public users: any = [];
  public events: any = [];
  public bookings: any = [];
  public s3_url = environment.s3_bucket_url;
  public showSearchResults: boolean = false;
  public isPackageActive: boolean = false;
  moment: any = moment;
  isUserMenuOpen: boolean = false;

  subscription = new SubSink();

  isAgeCheckPopoverOpen: boolean = false;
  dob: string = null;
  date_pipe = new DatePipe('en-us');
  threeYearsBeforeDate: string = moment().subtract(3,'years').format('YYYY-MM-DD');
  dateErrorMessage: string = '';

  constructor(
    private platform: PlatformService,
    public router: Router,
    private menu: MenuService,
    private auth: AuthService,
    public popover: PopoverController,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    private theme: ThemeService,
    private menuCtrl: MenuController,
    private plt: Platform,
    private fcm:FcmService,
    private loggedInService: IsUserLoggedInService,
    private accountInfoService: AccountInformationService,
    private pushNotifService: PushNotificationsService,
    private publicApiService: PublicApiService,
    private broadcastService : BroadcastService,
    private apiService: ApiService,
    @Optional() private fire_auth: Auth,
    private adsService: AdsService
  ) {

    this.plt.ready().then(()=>{
      this.isMobileDevice = this.platform.isMobile();
      this.isMobileApp = this.platform.isMobileApp();
      this.isiOSApp = this.platform.isiOSApp();

      this.showOnboardingScreen();

      this.pages$ = this.menu.pages$;
      this.auth$ = this.auth.auth$;
      this.currentTheme$ = this.theme.currentTheme$;
      this.themes$ = this.theme.themes$;
      this.router.events.subscribe((event: RouterEvent) => {
        this.selectedPath = event.url;
      });

      this.subscription.sink = this.loggedInService._userStatus.subscribe(async (value) => {
        console.log("user logged status", value);
        this.toggleMenu();
        this.finalStatus = value;
        this.getUserData();
        if(this.finalStatus){
          //Call api to check user package activation
          this.subscription.sink = this.apiService.get('users/subscription-active').subscribe((res) => {
            if(res && res.data && res.data.next_payment_date){
              let date = new Date(res.data.next_payment_date);
              const subscriptionExpires = new Date(date).getTime();
              const today = new Date().getTime();
              if(today < subscriptionExpires){
                this.isPackageActive = true;
                this.loggedInService.updateUserSubscriptionStatus(true);
              }else{
                this.isPackageActive = false;
                this.loggedInService.updateUserSubscriptionStatus(false);
              }
            }else{
              this.isPackageActive = false;
              this.loggedInService.updateUserSubscriptionStatus(false);
            }
          }, (err) => {
            console.log("Error!", err);
          });
        }else{
          this.loggedInService.updateUserSubscriptionStatus(false);
        }
      });

      this.subscription.sink = this.loggedInService._subscribeStatus.subscribe((value) => {
        this.isPackageActive = value;
      });
      // this.loggedInService._subscribeStatus.subscribe(async (value) => {
      //   console.log("subscription status in app component ====>", value);
      // })



      // this.pushNotifService.addPushNotificationListeners();
    })

  }
  async ngOnInit(): Promise<void> {

    this.plt.ready().then(async ()=>{
      // this.fcm.try();
      const width = this.plt.width();
      this.isUserLoggedIn = await this.checkUserLoggedIn();
      this.toggleMenu();
    });
    // this.setAccessTokeninStorage();
  }

  setAccessTokeninStorage() {
    // this.fire_auth.onAuthStateChanged((user: any) => {
    //   localStorage.setItem('token',user?.accessToken);
    // });
    this.fire_auth.onIdTokenChanged(user => {
      // console.log(user);
      if (user) {
        user.getIdToken().then(token => {
          // console.log(token);
          // localStorage.setItem('token',token)
        });
      }
    });
  }

  calculateAge() {
    console.log("date of birth ==> ",this.dob);
    var current_year = moment().year();
    var selected_year = moment(this.dob).format('YYYY');
    var age = current_year - parseInt(selected_year);
    console.log("age ==> ", age);
    if (age >= 21) {
      this.isiOSApp ? this.setOver21AdsStorage() : this.setOver21AdsCookie();
    } else {
      this.isiOSApp ? this.setUnder21AdsStorage() : this.setUnder21AdsCookie();
    }
  }

  getCookie(c_name) {
    if (document.cookie.length > 0) {
      let c_start = document.cookie.indexOf(c_name + "=");
      if (c_start != -1) {
        c_start = c_start + c_name.length + 1;
        let c_end = document.cookie.indexOf(";", c_start);
        if (c_end == -1)
          c_end = document.cookie.length;
        // console.log("cookie ===> ",unescape(document.cookie.substring(c_start, c_end)));
        return unescape(document.cookie.substring(c_start, c_end));
      }
    }
    return "";
  }

  closeAgeCheckPopover() {
    this.isAgeCheckPopoverOpen = false;
    // return false;
  }

  setOver21AdsCookie() {
    document.cookie = "allowOver21Ads=true";
    this.adsService.changeAdsStatus(true);
    // console.log("home ads changed over");

    this.closeAgeCheckPopover();
  }

  setUnder21AdsCookie() {
    document.cookie = "allowOver21Ads=false";
    this.adsService.changeAdsStatus(true);
    // console.log("home ads changed under");
    this.closeAgeCheckPopover();
  }

  setOver21AdsStorage() {
    localStorage.setItem('allowOver21Ads', 'true');
    this.adsService.changeAdsStatus(true);
    this.closeAgeCheckPopover();
  }

  setUnder21AdsStorage(){
    localStorage.setItem('allowOver21Ads', 'false');
    this.adsService.changeAdsStatus(true);
    this.closeAgeCheckPopover();
  }

  hasStoredAgeStorageValue(): boolean {
    let ageStorageKey = JSON.parse(localStorage.getItem('allowOver21Ads'));
    if (ageStorageKey === undefined || ageStorageKey === null) {
      return false;
    } else {
      return true;
    }
  }

  getUserData(){
    const storage_data = JSON.parse(localStorage.getItem('user_profile'));
    if (storage_data?.first_name !== undefined &&  this.finalStatus) {
      this.displayName = storage_data.first_name + ' ' + storage_data.last_name;
      this.accountType = storage_data.account_type;
      this.profilePic = storage_data.profile_image ? environment.s3_bucket_url + storage_data.profile_image : this.profilePic;
    }else{
      this.profilePic = '../assets/images/profile-placeholder.png';
    }
  }

  @HostListener('window:resize', ['$event'])
  private onResize(event){
    this.toggleMenu();
  }

  async checkUserLoggedIn() {
    // const storage_data = JSON.parse(
    //   (await Preferences.get({ key: 'user_data' })).value
    // );
    const storage_data = JSON.parse(localStorage.getItem('user_data'));
    if (storage_data !== undefined && storage_data !== null) {
      this.loggedInService.changeUserStatus(true);
      this.finalStatus = true;
      return true;
    }else{
      this.loggedInService.changeUserStatus(false);
      this.finalStatus = false;
      return false;
    }
  }

  navigateToPage(url: string){
    this.menu.changePage(url);
  }

  async setTheme(color: string){
    this.theme.setTheme(color);
  }

  async logOut(){
    this.loggedInService.changeUserStatus(0);
    await this.auth.logout();
    this.router.navigate(['login']);
  }
  async logOutPopover(){
    this.popover.dismiss();
    this.logOut();
  }

  setTitle(title){
    this.title = title;
  }

  toggleMenu() {
    const width = this.plt.width();
    if (width > 1023) {
      this.menuCtrl.enable(false, 'myMenu');
    } else {
      this.menuCtrl.enable(true, 'myMenu');
    }
  }
  // hideDropdown(event){
  //   const xTouch = (event.clientX).toFixed(2);
  //   const yTouch = (event.clientY).toFixed(2);

  //   const rect = this.userprofile.nativeElement.getBoundingClientRect();
  //   const topBoundry = (rect.top+2).toFixed(2);
  //   const leftBoundry = (rect.left+2).toFixed(2);
  //   const rightBoundry = (rect.right-2).toFixed(2);

  //   if(xTouch < leftBoundry || xTouch > rightBoundry || yTouch < topBoundry){
  //     this.dropdown = false;
  //   }
  // }
  setCurrentTab(ev: any){
    // console.log(ev);
    this.currentTab = ev.tab;
  }
  async popClick(event){
    const pop = this.popover.create({
      component: PopoverComponent,
      event,
      componentProps: {activeStatus: this.isPackageActive}
    });
    return (await pop).present();
  }

  goToProfileSettings() {
    this.router.navigate(['profile-settings']);
  }

  goToBookingRequests() {
    this.router.navigate(['booking-requests']);
  }

  goToPage(link) {
    this.router.navigate([link]);
  }

  goToPopoverPageLink(link) {
    this.popover.dismiss();
    this.router.navigate([link]);
  }


  goToProfilePage(){
    this.router.navigate(['profile']);
  }

  async onSearchChange(event){
    this.users = [];
    this.events = [];
    this.bookings = [];
    // console.log(event.target.value);
    if(event.target.value != ''){
      this.publicApiService.get(`search/${event.target.value}`).subscribe((data) => {
        this.users = data.users;
        this.events = data.events;
        this.bookings = data.bookings;
        if(this.users.length > 0 || this.events.length > 0 || this.bookings.length > 0)
          this.showSearchResults = true;
      });
    }else{
      this.showSearchResults = false;
    }
  }

  goToProfile(id, account_type){
    let type = 'user';
    if(account_type == 1)
      type = 'band';
    else if(account_type == 2)
      type = 'comedian';
    else if(account_type == 3)
      type = 'food-truck';
    else if(account_type == 4)
      type = 'user';
    else if(account_type == 5)
      type = 'venue';
    else if(account_type == 6)
      type = 'comedy-club';
    else if(account_type == 7)
      type = 'artist';
    let userId = btoa(id);
    this.showSearchResults = false;
    this.router.navigate([`${type}/${userId}`]);
  }

  showOnboardingScreen() {
    const onboarding = localStorage.getItem('isOnboardingVisible');
    if (this.isMobileApp && (onboarding === undefined || onboarding === null)) {
      localStorage.setItem('isOnboardingVisible', 'true');
      this.router.navigate(['onboarding-explore']);
    } else {
      this.showAgeVerifyPopover();
    }
  }

  showAgeVerifyPopover() {
    if (this.isiOSApp) {
      if (!this.hasStoredAgeStorageValue()) {
        this.isAgeCheckPopoverOpen = true;
      }
    } else {
      if (!this.getCookie('allowOver21Ads')) {
        this.isAgeCheckPopoverOpen = true;
      }
    }
  }

  async redirectToTickets() {
    await Browser.open({ url: 'https://www.alllocalevents.com/' });
  }

  dateChange() {
    if (this.dob > this.threeYearsBeforeDate) {
      this.dateErrorMessage = "You must be at least 3 years old";
      this.dob = "";
    } else {
      this.dateErrorMessage = "";
    }
  }

  showProfileDropdown(){
    this.isUserMenuOpen = true;
  }

  onWillDismiss(e){
    this.isUserMenuOpen = false;
  }

  closeModal(){
    this.isUserMenuOpen = false;
  }

}
