import { PlatformService } from 'src/app/services/platform/platform.service';
import { PushNotificationsService } from 'src/app/services/push-notifications/push-notifications.service';
import { ApiService } from './../api/api.service';
import { Injectable, Optional } from '@angular/core';
import {
  Auth,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
} from '@angular/fire/auth';
import { ToastController } from '@ionic/angular';
import { FirebaseError } from 'firebase/app';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AuthState } from './auth.model';
import jwt_decode from 'jwt-decode';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { getAuth, sendEmailVerification } from 'firebase/auth';
import { IsUserLoggedInService } from '../user/is-user-logged-in.service';
import { AccountInformationService } from '../account-information/account-information.service';
import { SubSink } from 'subsink';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token
} from '@capacitor/push-notifications';
import { Channel, LocalNotifications } from '@capacitor/local-notifications';

const initialAuthState: AuthState = {
  isLoggedIn: false,
  isEmailVerified: false,
  id: null,
  email: null,
  name: null,
  phone_number: null,
  token: null,
  display_picture: null,
  cover_photo: null,
  role: null,
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  //Loaders
  private isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  readonly isLoading$ = this.isLoading.asObservable();

  //Auth State Management
  public readonly _authState = new BehaviorSubject<AuthState>(initialAuthState);
  readonly auth$ = this._authState.asObservable();

  subscription = new SubSink();

  isUserExists: boolean;

  fb_login_error: any;
  fb_register_error: any;
  fb_reset_error: any;

  verifyEmailMessage: Subject<any> = new Subject<any>();
  toast_message: string = null;

  isMobileDevice: boolean;
  user_device_token: string = null;

  constructor(
    @Optional() public auth: Auth,
    private toast: ToastController,
    private router: Router,
    private userCheck: IsUserLoggedInService,
    private accountInfoService: AccountInformationService,
    private apiService: ApiService,
    private pushNotifService: PushNotificationsService,
    private platform: PlatformService
  ) {
    this.isMobileDevice = this.platform.isMobileApp();
    // if (this.isMobileDevice) {
    //   this.addPushNotificationListeners();
    // }

    // this.auth.onIdTokenChanged((user) => {
    //   if (user) {
    //     user.getIdToken(true).then((token) => {
    //       const token_decoded: any = jwt_decode(token);
    //       let u: AuthState = {
    //         isLoggedIn: false,
    //         isEmailVerified: user.emailVerified,
    //         id: user.uid,
    //         email: user.email,
    //         name: user.displayName,
    //         phone_number: user.phoneNumber,
    //         token: token,
    //         display_picture:
    //           'https://www.biography.com/.image/ar_1:1%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cq_auto:good%2Cw_1200/MTc5ODc1NTM4NjMyOTc2Mzcz/gettyimages-693134468.jpg', // user.photoURL,
    //         role: token_decoded.role,
    //         cover_photo:
    //           'https://www.indiewire.com/wp-content/uploads/2022/05/37507-original.jpeg', // token_decoded.cover_photo,
    //       };

    //       this._authState.next(u);
    //     });
    //   }
    // });

    // this.auth.onAuthStateChanged((user) => {
    //   if (!user) {
    //     this.isLoading.next(false);
    //     this._authState.next(initialAuthState);
    //     return;
    //   }
    // });
  }


  async refreshToken() {
    const user = this.auth.currentUser;
    const token = await user.getIdToken(true);

    let currentAuthState = this._authState.value;
    currentAuthState.token = token;
    this._authState.next(currentAuthState);
    this.setLocalStorage(this._authState);
  }

  setLocalStorage(user) {
    localStorage.setItem('user_data', JSON.stringify(user));
  }


  removeLocalStorage() {
    localStorage.removeItem('user_data');
  }

  // async registerAccountWithEmailPassword(user: create_user) {
  //   this.isLoading.next(true);
  //   createUserWithEmailAndPassword(this.auth, user.email, user.password)
  //     .then(async (data) => {
  //       data.user.getIdToken(true).then(async (token) => {
  //         const token_decoded: any = jwt_decode(token);
  //         if (!token_decoded.role) {
  //           let u: new_user = {
  //             uid: data.user.uid,
  //             name: user.name,
  //             email: data.user.email,
  //             role: user.role,
  //           };
  //           await this.post('auth?type=new-user', u, token)
  //             .pipe(take(1))
  //             .subscribe(
  //               async (res) => {
  //                 if (res) {
  //                   this.isLoading.next(false);
  //                   await data.user.getIdToken(true);
  //                   this.router.navigate(['home'], { replaceUrl: true });
  //                 }
  //               },
  //               async (err) => {
  //                 const t = await this.toast.create({
  //                   header: 'Error',
  //                   message:
  //                     'There was an issue creating this new user. Please try again shortly.',
  //                   position: 'bottom',
  //                   duration: 5000,
  //                   color: 'danger',
  //                 });
  //                 await t.present();
  //                 this.isLoading.next(false);
  //               }
  //             );
  //         }
  //       });
  //     })
  //     .catch(async (err: FirebaseError) => {
  //       this.isLoading.next(false);
  //       if (err.code === 'auth/email-already-in-use') {
  //         const toast = await this.toast.create({
  //           header: 'User already exists',
  //           message:
  //             'A user with this email address already exists, please log in instead of creating a new account.',
  //           position: 'bottom',
  //           duration: 3000,
  //         });
  //         await toast.present();
  //       } else {
  //         const toast = await this.toast.create({
  //           header: `Error: ${err.code}`,
  //           message:
  //             'There was a problem creating your new account. Try again later or contact our support team.',
  //           position: 'bottom',
  //           duration: 3000,
  //         });
  //         await toast.present();
  //       }
  //     });
  // }

  async loginWithEmailPassword(email: string, password: string) {
    this.isLoading.next(true);

    await signInWithEmailAndPassword(this.auth, email, password)
      .then((user:any) => {
        this.fb_login_error = undefined;
        console.log(user);
        localStorage.setItem('token', user.user.accessToken);
        if (getAuth().currentUser.emailVerified) {
          this.setLocalStorage(user);
          this.isUserExist();
        } else {
          if (this.router.url === '/register') {
            this.sendVerifyEmail();
            signOut(this.auth);
            this.router.navigate(['login']);
          } else {
            let message = "Your email is not verified yet. A verification email has already been sent to your registered email. Follow the link in that email to verify your account. If you haven't received the email, click the link below to resend the verification email.";
            this.verifyEmailMessage.next(message);
            // this.presentEmailSentToast(toast_message);
          }
        }

      })
      .catch((err: FirebaseError) => {
        if (err.code === 'auth/wrong-password') {
          this.fb_login_error = 'Wrong Password';
        } else if (err.code === 'auth/user-not-found') {
          this.fb_login_error = 'User Not Found';
        } else if (err.code === 'auth/user-disabled') {
          this.fb_login_error = 'Your account is currently disabled. Please contact your admin to enable your account.';
        } else {
          this.fb_login_error =
            'There was problem signing in your account, try again later.';
        }
      })
      .finally(() => {
        this.isLoading.next(false);
      });
  }

  // async signup(user) {
  //   this.isLoading.next(true);
  //   await createUserWithEmailAndPassword(this.auth, user.email, user.password)
  //     .then((userCredentials) => {
  //       this.fb_register_error = undefined;
  //       const user = userCredentials.user;
  //     })
  //     .catch((err: FirebaseError) => {
  //       this.fb_register_error = err;
  //     })
  //     .finally(()=>{
  //       this.isLoading.next(false);
  //     });
  // }

  isUserExist() {
    this.subscription.sink = this.checkUserExist().subscribe(
      async (res: any) => {
        await this.getStoredUserData();
        if (this.isMobileDevice) {
          await this.addPushNotificationListeners();
        }
        this.router.navigate(['home'], { replaceUrl: true });
      },
      (error) => {
        this.removeLocalStorage();
        if (error.status === 500) {
          this.presentErrorToast(error.error.message);
        } else {
          let message = "An error occurred signing in your account, please try again later.";
          this.presentErrorToast(message);
        }
      }
    );
  }

  updateDeviceToken(data) {
    this.subscription.sink = this.pushNotifService.updateDeviceToken(data).subscribe(
      () => {
      },
      (error) => console.log(error)
    );
  }

  checkUserExist(): Observable<any> {
    return this.apiService.get('users/user-exist');
  }

  addPushNotificationListeners() {
    try {
      // Request permission to use push notifications
      // iOS will prompt user and return if they granted permission or not
      // Android will just grant without prompting
      PushNotifications.requestPermissions().then(result => {
        if (result.receive === 'granted') {
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register();
        } else {
          // alert('an error occurs');
          // Show some error
        }
      });
      try {
        // On success, we should be able to receive notifications
        PushNotifications.addListener('registration',
        (token: Token) => {
          this.user_device_token = token.value;
          let data = {
            device_token: this.user_device_token
          };
          console.log(this.user_device_token);

          this.updateDeviceToken(data);
        }
        );
      } catch (error) {
        console.error(error);
      }
      // Some issue with our setup and push will not work
      PushNotifications.addListener('registrationError',
        (error: any) => {
          // alert('Error on registration: ' + JSON.stringify(error));
        }
      );

      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener('pushNotificationReceived',
        (notification: PushNotificationSchema) => {
          this.LocalNotifications(notification);
          // alert('Push received: ' + JSON.stringify(notification.title));
        }
      );

      // Method called when tapping on a notification
      PushNotifications.addListener('pushNotificationActionPerformed',
        (notification: ActionPerformed) => {
          // alert('Push action performed: ' + JSON.stringify(notification));
        }
      );
    } catch (error) {
      console.error(error);
      // alert(
      //   "Error occured getting permission to show local notification"
      // );
    }


  }
  startNotification(){
    PushNotifications.addListener('pushNotificationReceived',
    (notification: PushNotificationSchema) => {
      this.LocalNotifications(notification);
      // alert('Push received: ' + JSON.stringify(notification.title));
    }
  );
  }


  async LocalNotifications(data?:any) {
    try {
      await LocalNotifications.requestPermissions().then(async (res) => {
        if (res) {
          const notificationChannel: Channel = {
            id: "main",
            name: "Main Notifications",
            description: "Main Notifications Channel",
            importance: 5,
            visibility: 1,
            lights: true,
            vibration: true,

          };
          await LocalNotifications.createChannel(notificationChannel);
          await LocalNotifications.schedule({
            notifications: [
              {
                title: data.title,
                body: data.body,
                id: Math.floor(Math.random() * 10000) + 1,
                channelId: "main",
                smallIcon: '/assets/logos/bgimage@2x.png'
              },
            ],
          });
        }
      });
    } catch (error) {
      console.error(error);
      // alert(
      //   "Error occured getting permission to show local notification"
      // );
    }
  }

  async reset_password(email: string) {
    this.isLoading.next(true);
    await sendPasswordResetEmail(this.auth, email['email'])
      .then(() => {
        this.fb_reset_error = undefined;
      })
      .catch((err: FirebaseError) => {
        if (err.code === 'auth/invalid-email') {
          this.fb_reset_error = 'Invalid Email';
        } else if (err.code === 'auth/user-not-found') {
          this.fb_reset_error = 'User Not Found';
        } else {
          this.fb_reset_error =
            'There was a problem sending email, try again later.';
        }
      })
      .finally(() => {
        this.isLoading.next(false);
      });
  }

  async logout() {
    this.removeLocalStorage();
    this.removeApiToken();
    this.removeUserProfileStorage();
    await signOut(this.auth);
  }

  getStoredUserData() {
    this.accountInfoService.getStoredUserData().subscribe((resp)=>{
      const res = resp[0];
      // console.log("after user login", res);
      let userProfile = {
        id: res.id,
        account_type_id: res.account_type_id,
        account_type: res.account_type.account_type,
        first_name: res.first_name,
        last_name: res.last_name,
        email: res.email,
        profile_image: res.user_account_information?.profile_image ?? null,
        cover_image: res.user_account_information?.cover_image ?? null,
        lat: res.user_contact_information?.lat ?? null,
        long: res.user_contact_information?.long ?? null,
      }
      this.setUserProfileStorage(userProfile);
      this.userCheck.changeUserStatus(true);
    },
    (error) => console.log(error)
    );
  }

  setUserProfileStorage(userProfile) {
    localStorage.setItem('user_profile', JSON.stringify(userProfile));
  }

  removeUserProfileStorage() {
    localStorage.removeItem('user_profile');
  }

  removeApiToken() {
    localStorage.removeItem('token');
  }

  sendVerifyEmail() {
    const auth = getAuth();
    sendEmailVerification(auth.currentUser)
      .then(() => {
        console.log("Email verification sent response ==== ");
        this.toast_message = "A verification email is sent to your registered email. Please follow the link in the email to verify your account.";
        this.presentEmailSentToast(this.toast_message);
        // Email verification sent!
      }).catch((err: FirebaseError) => {
        console.log("Error is found ===> ",err);
        this.toast_message = "A problem has occurred, please try again later.";
        this.presentErrorToast(this.toast_message);
      })
      .finally(() => {
        // this.isLoading.next(false);
      });
  }

  async presentEmailSentToast(toast_message) {
    const toast = await this.toast.create({
      color: 'success',
      message: toast_message,
      position: 'top',
      duration: 10000,
    });
    await toast.present();
  }

  async presentErrorToast(toast_message) {
    const toast = await this.toast.create({
      header: 'Failed',
      color: 'danger',
      message: toast_message,
      position: 'top',
      duration: 5000,
    });
    await toast.present();
  }


}
