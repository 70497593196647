import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable, from, throwError } from 'rxjs';
import { catchError, retry, switchMap } from 'rxjs/operators';
import { Auth } from '@angular/fire/auth';
import { map } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private firebaseUser: any;

  constructor(private auth: Auth) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('token');

    if (token) {
      request = this.addToken(request, token);
    }

    return next.handle(request).pipe(
      catchError((error) => {
        if (error.status === 401) {
          return this.handle401Error(request, next);
        } else {
          return throwError(error);
        }
      })
    );
  }

  private addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.firebaseUser = this.auth.currentUser;

    if (!this.firebaseUser) {
      return throwError('No user logged in');
    }

    return from(this.firebaseUser.getIdToken(true)).pipe(
      switchMap((token: any) => {
        localStorage.setItem('token',token);
        const newRequest = this.addToken(request, token);
        return next.handle(newRequest);
      }),
      catchError((error) => {
        console.error('Error refreshing token', error);
        return throwError(error);
      }),
      // retry(1),
      map((response) => response as HttpEvent<any>)
    );
  }
}
