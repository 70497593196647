import { VerifyEmailGuard } from './services/guards/verify-email.guard';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { canActivate, redirectLoggedInTo, redirectUnauthorizedTo } from '@angular/fire/auth-guard';

// const redirectAuthorizedToHome = () => pipe(customClaims, map((claims) => {
//   if(claims.role){
//     return ['home'];
//   }
//   return true;
// }));

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const redirectAuthorizedToHome = () => redirectLoggedInTo(['/']);

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./views/home/home.module').then( m => m.HomePageModule),
  },
  {
    path: 'home',
    loadChildren: () => import('./views/home/home.module').then( m => m.HomePageModule),
  },
  {
    path: 'calendar',
    loadChildren: () => import('./views/calendar/calendar.module').then( m => m.CalendarPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./views/login/login.module').then( m => m.LoginPageModule),
    canActivate: [VerifyEmailGuard]
  },
  {
    path: 'register',
    loadChildren: () => import('./views/register/register.module').then( m => m.RegisterPageModule),
    canActivate: [VerifyEmailGuard]
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./views/reset-password/reset-password.module').then( m => m.ResetPasswordPageModule),
    canActivate: [VerifyEmailGuard]
  },
  {
    path: 'profile-settings',
    loadChildren: () => import('./views/profile-settings/profile-settings.module').then( m => m.ProfileSettingsPageModule),
    ...canActivate(redirectUnauthorizedToLogin)
  },
  {
    path: 'archive',
    loadChildren: () => import('./views/archive/archive.module').then( m => m.ArchivePageModule)
  },
  {
    path: 'video-detail/:id',
    loadChildren: () => import('./views/video-detail/video-detail.module').then( m => m.VideoDetailPageModule)
  },
  {
    path: 'account-videos/:userId',
    loadChildren: () => import('./views/account-videos/account-videos.module').then( m => m.AccountVideosPageModule)
  },
  {
    path: 'user/:userId',
    loadChildren: () => import('./views/user/user.module').then( m => m.UserPageModule)
  },
  {
    path: 'band/:userId',
    loadChildren: () => import('./views/user/user.module').then( m => m.UserPageModule)
  },
  {
    path: 'venue/:userId',
    loadChildren: () => import('./views/user/user.module').then( m => m.UserPageModule)
  },
  {
    path: 'food-truck/:userId',
    loadChildren: () => import('./views/user/user.module').then( m => m.UserPageModule)
  },
  {
    path: 'comedian/:userId',
    loadChildren: () => import('./views/user/user.module').then( m => m.UserPageModule)
  },
  {
    path: 'comedy-club/:userId',
    loadChildren: () => import('./views/user/user.module').then( m => m.UserPageModule)
  },
  {
    path: 'artist/:userId',
    loadChildren: () => import('./views/user/user.module').then( m => m.UserPageModule)
  },

  {
    path: 'listing',
    loadChildren: () => import('./views/listing/listing.module').then( m => m.ListingPageModule)
  },
  {
    path: 'bookings',
    loadChildren: () => import('./views/bookings/bookings.module').then( m => m.BookingsPageModule),
    ...canActivate(redirectUnauthorizedToLogin)
  },
  {
    path: 'inbox',
    loadChildren: () => import('./views/users-chat/users-chat.module').then( m => m.UsersChatPageModule),
    ...canActivate(redirectUnauthorizedToLogin)
  },
  {
    path: 'profile',
    loadChildren: () => import('./views/user-profile/user-profile.module').then( m => m.UserProfilePageModule)
  },
  {
    path: 'booking-requests',
    loadChildren: () => import('./views/booking-requests/booking-requests.module').then( m => m.BookingRequestsPageModule),
    ...canActivate(redirectUnauthorizedToLogin)
  },
  {
    path: 'favorite',
    loadChildren: () => import('./views/favorite/favorite.module').then( m => m.FavoritePageModule),
    ...canActivate(redirectUnauthorizedToLogin)
  },
  {
    path: 'notifications',
    loadChildren: () => import('./views/notifications/notifications.module').then( m => m.NotificationsPageModule),
    ...canActivate(redirectUnauthorizedToLogin)
  },
  {
    path: 'video-stats',
    loadChildren: () => import('./views/video-stats/video-stats.module').then( m => m.VideoStatsPageModule),
    ...canActivate(redirectUnauthorizedToLogin)
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./views/privacy-policy/privacy-policy.module').then( m => m.PrivacyPolicyPageModule)
  },
  {
    path: 'terms-conditions',
    loadChildren: () => import('./views/terms-conditions/terms-conditions.module').then( m => m.TermsConditionsPageModule)
  },
  {
    path: 'membership',
    loadChildren: () => import('./views/membership/membership.module').then( m => m.MembershipPageModule),
    ...canActivate(redirectUnauthorizedToLogin)
  },
  {
    path: 'change-password',
    loadChildren: () => import('./views/change-password/change-password.module').then( m => m.ChangePasswordPageModule),
    ...canActivate(redirectUnauthorizedToLogin)
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./views/contact-us/contact-us.module').then( m => m.ContactUsPageModule)
  },
  {
    path: 'tips-balance',
    loadChildren: () => import('./views/tips-balance/tips-balance.module').then( m => m.TipsBalancePageModule),
    ...canActivate(redirectUnauthorizedToLogin)
  },
  {
    path: 'archive-video-detail',
    loadChildren: () => import('./components/archive-video-detail/archive-video-detail.module').then( m => m.ArchiveVideoDetailPageModule)
  },
  {
    path: 'onboarding-explore',
    loadChildren: () => import('./views/onboarding-explore/onboarding-explore.module').then( m => m.OnboardingExplorePageModule)
  },
  // {
  //   path: 'account-activation',
  //   loadChildren: () => import('./views/account-activation/account-activation.module').then( m => m.AccountActivationPageModule)
  // },
  {
    path: '404',
    loadChildren: () => import('./views/not-found-page/not-found-page.module').then( m => m.NotFoundPagePageModule)
  },
  {
    path: '**',
    redirectTo: '404'
  },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
