import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthState } from '../auth/auth.model';

// const GOOGLE_MAPS_API_KEY = 'AIzaSyDSC5ZkhYKqG7yn3tOCXGS6FnimqUU2ZP0';
// export type Maps = typeof google.maps;
const initialAuthState: AuthState = {
  isLoggedIn: false,
  isEmailVerified: false,
  id: null,
  email: null,
  name: null,
  phone_number: null,
  token: null,
  display_picture: null,
  cover_photo: null,
  role: null,
};
@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private token: string = '';

  private readonly _authState = new BehaviorSubject<AuthState>(
    initialAuthState
  );

  readonly auth$ = this._authState.asObservable();

  constructor(private http: HttpClient) {
  }

  getToken() {
    return localStorage.getItem('token');
  }

  get(url: string): Observable<any> {
    // if (this.isUserLoggedIn()) {
    //   this.token = this.getToken();
    //   const headers = {
    //     'Content-Type': 'application/json',
    //     // Authorization: `Bearer ${this.token}`,
    //   };
      return this.http.get(environment.base_url + url);
    // }
    //  else {
    //   return this.http.get(environment.base_url + url);
    // }
  }

  post(url: string, body: any): Observable<any> {
    // if (this.isUserLoggedIn()) {
    //   this.token = this.getToken();
    //   const headers = {
    //     'Content-Type': 'application/json',
    //     // Authorization: `Bearer ${this.token}`,
    //   };
      return this.http.post(environment.base_url + url, body);
    // }
    // else {
    //   return this.http.post(environment.base_url + url, body);
    // }
  }

  put(url: string, body: any): Observable<any> {
    // this.token = this.getToken();
    // const headers = {
    //   'Content-Type': 'application/json',
    //   // Authorization: `Bearer ${this.token}`,
    // };
    return this.http.put(environment.base_url + url, body);
  }

  delete(url: string): Observable<any> {
    // this.token = this.getToken();
    // const headers = {
    //   'Content-Type': 'application/json',
    //   // Authorization: `Bearer ${this.token}`,
    // };
    // return this.http.delete(environment.base_url + url, {
    //   headers: new HttpHeaders(headers),
    // });
    return this.http.delete(environment.base_url + url);
  }

  postFile(url: string, body: any): Observable<any> {
    // this.token = this.getToken();
    // const headers = {
    //   // Authorization: `Bearer ${this.token}`,
    // };
    // return this.http.post(environment.base_url + url, body, {
    //   headers: new HttpHeaders(headers),
    // });
    return this.http.post(environment.base_url + url, body);
  }

  putFile(url: string, body: any): Observable<any> {
    // this.token = this.getToken();
    // const headers = {
    //   // Authorization: `Bearer ${this.token}`,
    // };
    // return this.http.put(environment.base_url + url, body, {
    //   headers: new HttpHeaders(headers),
    // });
    return this.http.put(environment.base_url + url, body);
  }

  isUserLoggedIn() {
    const storage_data = JSON.parse(localStorage.getItem('user_data'));
    console.log('storage_data', storage_data);
    if (storage_data && storage_data !== undefined && storage_data !== null) {
      return true;
    } else {
      return false;
    }
  }

  getUserId() {
    const storage_data = JSON.parse(localStorage.getItem('user_data'));
    return storage_data.user.uid;
  }

  // public readonly api = this.load();

  // private load(): Promise<Maps> {
  //   const script = document.createElement('script');
  //   script.type = 'text/javascript';
  //   script.async = true;
  //   script.defer = true;
  //   // tslint:disable-next-line:no-bitwise
  //   const callbackName = `GooglePlaces_cb_` + ((Math.random() * 1e9) >>> 0);
  //   script.src = this.getScriptSrc(callbackName);

  //   interface MyWindow { [name: string]: Function; };
  //   const myWindow: MyWindow = window as any;

  //   const promise = new Promise((resolve, reject) => {
  //     myWindow[callbackName] = resolve;
  //     script.onerror = reject;
  //   });
  //   document.body.appendChild(script);
  //   return promise.then(() => google.maps);
  // }

  // private getScriptSrc(callback: string): string {
  //   interface QueryParams { [key: string]: string; };
  //   const query: QueryParams = {
  //     v: '3',
  //     callback,
  //     key: GOOGLE_MAPS_API_KEY,
  //     libraries: 'places',
  //   };
  //   const params = Object.keys(query).map(key => `${key}=${query[key]}`).join('&');
  //   return `//maps.googleapis.com/maps/api/js?${params}&language=en`;
  // }
}
