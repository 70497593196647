import { ApiService } from 'src/app/services/api/api.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccountInformationService {

  private baseUrl = environment.base_url + `users`;

  constructor(private http: HttpClient, private apiService: ApiService) {}

  // createAccountInfo(accountInfo): Observable<any> {
  //   return this.apiService.postFile('users/createAccountInformation', accountInfo);
  // }

  // updateAccountInfo(accountInfo): Observable<any> {
  //   return this.apiService.postFile('users/updateAccountInformation', accountInfo);
  // }

  addAccountInfo(accountInfo): Observable<any> {
    return this.apiService.postFile('users/addAccountInformation', accountInfo);
  }

  getAccountInfo(): Observable<any> {
    return this.apiService.get('users/getAccountInformation');
    //return this.http.get<any>(this.baseUrl + `/getAccountInformation`);
  }

  enableDisableUserAccount(data) : Observable<any> {
    return this.apiService.post('users/enable-disable-account', data);
  }

  getStoredUserData():Observable<any> {
    return this.apiService.get('users/userProfile');
  }

  getUserProfile() {
    return JSON.parse(localStorage.getItem('user_profile'));
  }

}
