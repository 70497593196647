import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { theme } from './theme.model';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  themes: BehaviorSubject<theme[]> = new BehaviorSubject<theme[]>([]);
  readonly themes$: Observable<theme[]> = this.themes.asObservable();

  currentTheme: BehaviorSubject<string> = new BehaviorSubject<string>('default');
  readonly currentTheme$: Observable<string> = this.currentTheme.asObservable();

  constructor() {
    this.getAllThemes();
    this.setTheme();
  }

  async getAllThemes(){
    this.themes.next([
      {
        name: 'default',
        color: '#d2814a'
      },
      {
        name: 'red',
        color: '#eb445a'
      },
      {
        name: 'neon-green',
        color: '#ccd667'
      },
      {
        name: 'blue',
        color: '#6fb5b3'
      }
    ]);
  }

  async setTheme(color?: string){
    let theme = await localStorage.getItem('theme');
    if(theme && theme !== 'undefined'){
      this.currentTheme.next(theme);
      switch(theme){
        case 'default':
          document.documentElement.style.setProperty('--default-base', '#d2814a');
          document.documentElement.style.setProperty('--default-shade', '#b97241');
          document.documentElement.style.setProperty('--default-tint', '#d78e5c');
          document.documentElement.style.setProperty('--default-light', '#f5ebd7');
          document.documentElement.style.setProperty('--default-text', '#FFFFFF');
          document.documentElement.style.setProperty('--default-low-opacity', '#d2814a66');
          break;
        case 'red':
          document.documentElement.style.setProperty('--default-base', '#eb445a');
          document.documentElement.style.setProperty('--default-shade', '#cf3c4f');
          document.documentElement.style.setProperty('--default-tint', '#ed576b');
          document.documentElement.style.setProperty('--default-light', '#fad2d7');
          document.documentElement.style.setProperty('--default-text', '#FFFFFF');
          document.documentElement.style.setProperty('--default-low-opacity', '#eb445a66');
          break;
        case 'neon-green':
          document.documentElement.style.setProperty('--default-base', '#ccd667');
          document.documentElement.style.setProperty('--default-shade', '#b4bc5b');
          document.documentElement.style.setProperty('--default-tint', '#d1da76');
          document.documentElement.style.setProperty('--default-light', '#f0f3d3');
          document.documentElement.style.setProperty('--default-text', '#000000');
          document.documentElement.style.setProperty('--default-low-opacity', '#d1da7666');
          break;
        case 'blue':
          document.documentElement.style.setProperty('--default-base', '#6fb5b3');
          document.documentElement.style.setProperty('--default-shade', '#629f9e');
          document.documentElement.style.setProperty('--default-tint', '#7dbcbb');
          document.documentElement.style.setProperty('--default-light', '#e2f0ef');
          document.documentElement.style.setProperty('--default-text', '#FFFFFF');
          document.documentElement.style.setProperty('--default-low-opacity', '#7dbcbb66');
          break;
      }
    }

    if(color){
      if(color !== theme && color !== 'undefined'){
        // await localStorage.setItem('theme', color);
        this.currentTheme.next(color);
        switch(color){
          case 'default':
            document.documentElement.style.setProperty('--default-base', '#d2814a');
            document.documentElement.style.setProperty('--default-shade', '#b97241');
            document.documentElement.style.setProperty('--default-tint', '#d78e5c');
            document.documentElement.style.setProperty('--default-light', '#f5ebd7');
            document.documentElement.style.setProperty('--default-text', '#FFFFFF');
            document.documentElement.style.setProperty('--default-low-opacity', '#d2814a66');
            break;
          case 'red':
            document.documentElement.style.setProperty('--default-base', '#eb445a');
            document.documentElement.style.setProperty('--default-shade', '#cf3c4f');
            document.documentElement.style.setProperty('--default-tint', '#ed576b');
            document.documentElement.style.setProperty('--default-light', '#fad2d7');
            document.documentElement.style.setProperty('--default-text', '#FFFFFF');
            document.documentElement.style.setProperty('--default-low-opacity', '#eb445a66');
            break;
          case 'neon-green':
            document.documentElement.style.setProperty('--default-base', '#ccd667');
            document.documentElement.style.setProperty('--default-shade', '#b4bc5b');
            document.documentElement.style.setProperty('--default-tint', '#d1da76');
            document.documentElement.style.setProperty('--default-light', '#f0f3d3');
            document.documentElement.style.setProperty('--default-text', '#000000');
            document.documentElement.style.setProperty('--default-low-opacity', '#d1da7666');
            break;
          case 'blue':
            document.documentElement.style.setProperty('--default-base', '#6fb5b3');
            document.documentElement.style.setProperty('--default-shade', '#629f9e');
            document.documentElement.style.setProperty('--default-tint', '#7dbcbb');
            document.documentElement.style.setProperty('--default-light', '#e2f0ef');
            document.documentElement.style.setProperty('--default-text', '#FFFFFF');
            document.documentElement.style.setProperty('--default-low-opacity', '#7dbcbb66');
            break;
        }
      }
    }
  }
}
