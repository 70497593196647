import { Injectable } from '@angular/core';
import { of, ReplaySubject, Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IsUserLoggedInService {

  // _status: boolean = false;
  _userStatus = new BehaviorSubject<boolean>(false);

  // _subscribe: boolean = false;
  _subscribeStatus =  new BehaviorSubject<boolean>(false);

  get userStatus():Observable<boolean>{
    return this._userStatus.asObservable()
  }

  // set userStatus(status: Subject<boolean>){
  //   this._userStatus = status;
  // }

  get subscribeStatus():Observable<boolean>{
    return this._subscribeStatus.asObservable()
  }

  // set subscribeStatus(status: Subject<boolean>){
  //   this._subscribeStatus = status;
  // }

  constructor() { }


  changeUserStatus(status){
    this._userStatus.next(status);
  }

  updateUserSubscriptionStatus(status){
    this._subscribeStatus.next(status);
    // this._subscribeStatus.subscribe((value) => {
    //   console.log("subscription status", value);
    // })
  }
}
