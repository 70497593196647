import { IonicModule } from '@ionic/angular';
import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router, RouterEvent } from '@angular/router';
import { IsUserLoggedInService } from 'src/app/services/user/is-user-logged-in.service';
import { PopoverController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent implements OnInit {
  @Input() activeStatus: boolean;
  userId: string = '';
  // isPackageActive: boolean = false;

  constructor(
    private auth: AuthService,
    public router: Router,
    private userLogged: IsUserLoggedInService,
    private loggedInService: IsUserLoggedInService,
    private popover: PopoverController,
    private apiServices: ApiService
  ) {
    this.loggedInService._subscribeStatus.subscribe((value) => {
      console.log("123 subscription status in app component ====>", value);
    });
  }

  ngOnInit() {}

  async ionViewWillEnter(){
    // console.log("first");
    // if(this.isPackageActive){
    //   console.log("You have active package", this.isPackageActive)
    // }else{
    //   console.log("you have not active package", this.isPackageActive)
    // }


    this.userId = btoa(JSON.parse(localStorage.getItem('user_profile')).id);
  //  await this.apiServices.get('users/userProfile').subscribe((res: any) => {
  //   this.userId = btoa(res.id);
  //  });
  }

  async logOut(){
    this.userLogged.changeUserStatus(false);
    await this.auth.logout();
    this.popover.dismiss();
    this.router.navigate(['login']);
  }

  goToProfileSettings() {
    this.popover.dismiss();
    this.router.navigate(['profile-settings']);
  }

  goToBookingRequests() {
    this.popover.dismiss();
    this.router.navigate(['booking-requests']);
  }

  goToProfilePage(){
    this.popover.dismiss();
    this.router.navigate(['profile']);
  }
  userNotifications(){
    this.popover.dismiss();
    this.router.navigate(['notifications']);
  }
  goToPage(route){
    this.popover.dismiss();
    this.router.navigate([route]);
  }

  chatUsers(){
    this.popover.dismiss();
    this.router.navigate(['inbox']);
  }
}
