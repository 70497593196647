import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdsService {

  _adsStatus = new BehaviorSubject<boolean>(false);

  get adsStatus():Observable<boolean>{
    return this._adsStatus.asObservable();
  }

  constructor() { }


  changeAdsStatus(status){
    this._adsStatus.next(status);
  }

}
