import { Capacitor } from '@capacitor/core';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class PlatformService {
  constructor(private platform: Platform) {}


  public isMobile(): boolean {
    if (this.platform.is('android') || this.platform.is('ios') || this.platform.is('mobileweb')) {
      return true;
    } else {
      return false;
    }

  }

  public isMobileApp(): boolean {
    if (this.platform.is('hybrid')) {
      return true;
    } else {
      return false;
    }
  }

  public isiOSApp(): boolean {
    return this.platform.is('ios') ? true: false;
  }


}
