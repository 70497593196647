import { getAuth } from '@angular/fire/auth';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class VerifyEmailGuard implements CanActivate {
  constructor(
    private router: Router,
    private alertCtrl: AlertController,
) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return new Promise((resolve, reject) => {
        const storage_data = JSON.parse(localStorage.getItem('user_profile'));
        if (storage_data !== undefined && storage_data !== null) {
          // console.log("storage data");
          resolve(false);
          this.router.navigate([this.router.url]);
        } else {
          // console.log("no storage");
          resolve(true);
        }

        // getAuth().onAuthStateChanged(user => {
        //   if (user) {
        //     if (user.emailVerified) {
        //       resolve(true);
        //     } else {
        //       resolve(false);
        //       // this.resendEmailDialog();
        //       // this.router.navigate([this.router.url]);
        //     }
        //   } else {
        //     resolve(false);
        //     this.router.navigate(['/']);
        //   }
        // })

    })
  }

  async resendEmailDialog() {
    let alert = this.alertCtrl.create({
      message: "Your account is not verified yet. Please check your inbox for the verification email sent by Cincy Local and Live to verify your account. If you haven't received the email, click on the button to resend the verification email",
      buttons: [
        // {
        //   text: 'Cancel',
        //   role: 'cancel',
        //   handler: () => {
        //     console.log('Cancel clicked');
        //   }
        // },
        {
          text: 'Resend Verification Email',
          handler: () => {
            console.log('Booking called');
          }
        }
      ]
    });
    (await alert).present();
  }

}
