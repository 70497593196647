// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  // Production Firebase
  firebase: {
    apiKey: 'AIzaSyBlFMaukBjrVmB8EuG9o7xdKi4c1XVeLRQ',
    authDomain: 'cincy-local-and-live-stage.firebaseapp.com',
    projectId: 'cincy-local-and-live-stage',
    storageBucket: 'cincy-local-and-live-stage.appspot.com',
    messagingSenderId: '138701212350',
    appId: '1:138701212350:web:1d14493af7c700e2cfecef'
  },
  // Stage Firebase
  // firebase: {
  //   apiKey: 'AIzaSyDnxDdaqllYLoPjaxbB9PM61LT0m2nqCkI',
  //   authDomain: 'cincy-local-and-live-prod.firebaseapp.com',
  //   projectId: 'cincy-local-and-live-prod',
  //   storageBucket: 'cincy-local-and-live-prod.appspot.com',
  //   messagingSenderId: '110583298622',
  //   appId: '1:110583298622:web:d81cd7a1655de24a6cef6f'
  // },
  production: false,

  google_calendar_api_key: 'AIzaSyDSC5ZkhYKqG7yn3tOCXGS6FnimqUU2ZP0',
  items_per_page: 10,
  default_lat: 39.2947401,
  default_long: -84.5052922,

  /* STAGE Credentials */
  // base_url: "https://api-stage.cincylocalandlive.com/",
  // // base_url: "http://localhost:8000/",
  // s3_bucket_url: "https://cll-stage.s3.us-east-2.amazonaws.com/",
  // frontend_url: "https://stage.cincylocalandlive.com/",
  // stripe_key: 'pk_test_51M9nW8KoJc26PCGl48RsuSXyTTkHnIrMSH15Ih0kNgEaF7FbMSHSTi5n3mYb0ZAKueODnAiBzT2Q5x6GtJld5flN00CCpzxx0M',

  /* PRODUCTION Credentials */
  base_url: "https://api.cincylocalandlive.com/",
  s3_bucket_url: "https://cll-stage.s3.us-east-2.amazonaws.com/",
  frontend_url: "https://cincylocalandlive.com/",
  stripe_key: 'pk_live_51HviywD58pVq9euLVYRpTMzMa5BHKewab5DYTD5j5AlhvBEEjAHiFCD8Rg7YwLJHu9Cozh6fZkNcXSoUVw4hTRRT00ZwrB0xTy'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
